import React from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { AnimeButton } from "../../../../common/AnimeButton";
import { HR } from "../../../../common/horizontalLine";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextHeadingMain = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin-top:40px !important;
  @media (max-width: 900px) {
    font-size: 16px !important;
    width: 100% !important;
    margin-bottom: 20px !important;
  }
`;

const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  @media (max-width: 900px) {
    font-size: 16px !important;
    width: 100% !important;
    margin-bottom: 20px !important;
  }
`;

const TextBook = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-top:25px !important;
  @media (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  margin-right:80px;
  /* margin-top:25px !important; */
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    width:100%;
    text-decoration:underline !important;
  }
  `;

export const AnnualGeneralMeeting = (props) => {

    const [data, SetData] = React.useState({});

    React.useEffect(() => {
        axios({
            method: "get",
            url:
                config.serverUrl.base +
                "/jsonapi/node/37th_annual_general_meeting?include=field_speech1,field_speech2,field_speaker1,field_speaker2",
        })
            .then((res) => {
                var includedData = {};
                for (let item of res.data.included) {
                    includedData[item.id] = config.serverUrl.imageBase + item.attributes.uri.url;
                }
                SetData({
                    description: res.data.data[0].attributes.field_description.processed,
                    speech1Img: includedData[res.data.data[0].relationships.field_speaker1.data.id],
                    speech2Img: includedData[res.data.data[0].relationships.field_speaker2.data.id],
                    speech1Text: res.data.data[0].attributes.field_speech_1_title_1,
                    speech2Text: res.data.data[0].attributes.field_speech_1_title,
                    speech1File: includedData[res.data.data[0].relationships.field_speech1.data.id],
                    speech2File: includedData[res.data.data[0].relationships.field_speech2.data.id],
                })
                console.log(res, {
                    description: res.data.data[0].attributes.field_description.processed,
                    speech1Img: includedData[res.data.data[0].relationships.field_speaker1.data.id],
                    speech2Img: includedData[res.data.data[0].relationships.field_speaker2.data.id],
                    speech1Text: res.data.data[0].attributes.field_speech_1_title_1,
                    speech2Text: res.data.data[0].attributes.field_speech_1_title,
                    speech1File: includedData[res.data.data[0].relationships.field_speech1.data.id],
                    speech2File: includedData[res.data.data[0].relationships.field_speech2.data.id],
                });
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // {
    //     description:data.data[0].attributes.field_description.processed,
    //     speech1Title:data.data[0].attributes.field_speech_1_title_1,
    //     speech1Img:'',
    //     speech1File:'',
    //     speech2Title:data.data[0].attributes.field_speech_1_title,
    //     speech2Img:'',
    //     speech2File:'',
    // }

    return (
        <div>
            <TextHeadingMain dangerouslySetInnerHTML={{
                __html: data?.description
            }} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '40px', marginBottom: '30px' }} >
                <div style={{ height: '170px', width: '170px', minWidth: '170px', marginRight: window.matchMedia('(max-width: 820px)').matches ? '20px' : '60px', alignSelf: window.matchMedia('(max-width: 820px)').matches && 'flex-start' }} >
                    <img loading="lazy" src={data?.speech1Img} style={{ height: '170px', width: '170px', minWidth: '170px', objectFit: 'cover' }} />
                </div>
                <span style={{ flex: 1 }} >
                    <TextHeading>Speech</TextHeading>
                    <span style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: 820px)').matches ? 'column' : 'row', alignItems: window.matchMedia('(max-width: 820px)').matches ? 'unset' : 'center' }} >
                        <TextContent
                            to={{ pathname: data?.speech1File }}
                            target="_blank"
                            style={{ font: "18px" }}
                        // onClick={() => {
                        //     downloadFile(data?.speech1File);
                        // }}
                        >
                            {data?.speech1Text}
                        </TextContent>
                        <AnimeButton
                            href={data?.speech1File}
                            target="_blank"
                        //  onClick={() => {
                        //         downloadFile(data?.speech1File);
                        //     }}
                        />
                    </span>
                </span>
            </div>
            { /* <TextContent
                to={{pathname: "https://youtu.be/bEi8l-MIezQ"}}
                target="_blank"
                style={{ font: "18px" }}
            >
                Recording of 40th Annual General Meeting
        </TextContent> */ }
            <TextContent
                to={{pathname: "https://www.drreddys.com/cms/cms/sites/default/files/Co-Chairman_and_MD_Speech.pdf"}}
                target="_blank"
                style={{ font: "18px" }}
            // onClick={() => {
            //     downloadFile(data?.speech2File);
            // }}
            >
                Co-Chairman and Managing Director Speech
            </TextContent>
            <br /><br />
            <TextContent
                to={{pathname: "https://www.drreddys.com/cms/cms/sites/default/files/2024-07/40th_AGM_FY2024.pdf"}}
                target="_blank"
                style={{ font: "18px" }}
            // onClick={() => {
            //     downloadFile(data?.speech2File);
            // }}
            >
                Notice of 40th Annual General Meeting
            </TextContent>
            { /* <HR />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '30px' }} >
                <div style={{ height: '170px', width: '170px', minWidth: '170px', marginRight: window.matchMedia('(max-width: 820px)').matches ? '20px' : '60px', alignSelf: window.matchMedia('(max-width: 820px)').matches && 'flex-start' }} >
                    <img loading="lazy" src={data?.speech2Img} style={{ height: '170px', width: '170px', minWidth: '170px', objectFit: 'cover' }} />
                </div>
                <span style={{ flex: 1 }} >
                    <TextHeading>Speech</TextHeading>
                    <span style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: 820px)').matches ? 'column' : 'row', alignItems: window.matchMedia('(max-width: 820px)').matches ? 'unset' : 'center' }} >
                        <TextContent
                            to={{ pathname: data?.speech2File }}
                            target="_blank"
                            style={{ font: "18px" }}
                        // onClick={() => {
                        //     downloadFile(data?.speech2File);
                        // }}
                        >
                            {data?.speech2Text}
                        </TextContent>
                        <AnimeButton
                            href={data?.speech2File}
                            target="_blank"
                        // onClick={() => {
                        //         downloadFile(data?.speech2File);
                        //     }}
                        />
                    </span>
                </span>
        </div> */ }
        </div>
    )
}
