/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MenuSection from '../Common/MenuSection';
import FooterSection from '../Common/FooterSection';

import HeaderBanner from '../Common/LandingPage/HeaderBanner';
import ImageSlider from '../Common/LandingPage/ImageSlider';
import ImageSliderMobile from '../Common/LandingPage/ImageSliderMobile';

import WeAreReddys from '../Common/LandingPage/WeAreReddys';

import Lottie from 'react-lottie';
import * as arrowAnime from '../assets/Lottie/White_Arrow.json';

import axios from 'axios';
import { minimizeString, isTab, mediaQueryRangeTab } from '../Utils/Utils';
import config from '../config';
// import ImageSliderGsap from '../Common/AboutUsPage/ImageSliderGsap';
import HomeSlider from '../Common/MobileSlider/HomeSlider';
import WeAreReddysSlider from '../Common/MobileSlider/WeAreReddysSlider';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { SustainabilityTabs } from '../Common/LandingPage/SustainabilityTabs';

import { useMediaQuery } from "react-responsive";


import { HR } from "../pages/common/horizontalLine";

import rightArrowPrimary from '../assets/rightArrowPrimary.svg';

const isTabsEnable = true

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
`;

const MainTabContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
`;


const Heading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  line-height: 1 !important;
  margin-bottom: 35px !important;
`;

const TabsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
  margin-right: 4%;
`;

const TabItem = styled.a`
  cursor: pointer;
  text-decoration:none !important;
  font-size: 18px;
  color: ${({ selected }) => (selected ? '#5225B5' : '#909090')};
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : 400)} ;
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color:  #5225B5;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

const MobileTabItem = styled.a`
  cursor: pointer;
  text-decoration:none !important;
  font-size: 18px;
  color: #000000;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : 400)} ;
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color:  #5225B5;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
`;


const HambergerButton = styled.a`
  /* using following code for increasing clickable area */
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 2em;
  margin: -2em;
  cursor: pointer;
`;

const HambergerIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #000;
  border-bottom: 0.2em solid #000;
`;

const MenuContainer = styled.div`
  position: absolute;
  z-index: 99999999999999;
  width: 100%;
  display: block;
  background-color: #ededed;
  top: 0;
  left: 0;
  padding-top: 20%;
`;

const CloseButton = styled.a`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  margin-top: 10px;
  &::before {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(45deg);
  }
  &::after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(-45deg);
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const TabMenuItem = styled.a`
  cursor: pointer;
  font-size: 16px;
  color:  ${({ selected }) => (selected ? "#5225B5" : "#000")};;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  padding-bottom: 5px;
  text-decoration:none !important;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

// const isTabsEnable = useMediaQuery({ query: "(min-device-width: 1000px)" });

const TabData = [
  {
    id: 1, name: "Being committed to environmental stewardship", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 2, name: "Making our products accessible and affordable to patients", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 3, name: "Contributing to a fairer and more socially inclusive world", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 4, name: "Enhancing trust with our stakeholders", bgColor: '#fff', hashValue: '', subMenuTabData: []
  }
]


const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: arrowAnime,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class IndexView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articleData: {},
      renderPageBool: false,
      newsData: [],
      cookieBool: true,
      selectedTab: TabData[0],
      selectedSubTab: 1,
      tabData: TabData,
      openMenuBool: false,
      leftDisabled: true,
      rightDisabled: false
    }

    // this.renderMainComponents = this.renderMainComponents.bind(this);
    this.getTabContent = this.getTabContent.bind(this);
    this.getTabImage = this.getTabImage.bind(this);
    this.handleLeftClick = this.handleLeftClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }


  handleLeftClick() {
    console.log('came here...');

    var newTab = this.state.selectedTab.id - 1;
    var right = this.state.rightDisabled, left = this.state.leftDisabled;
    if (newTab < 1) { newTab = 1 };
    this.setState({ selectedTab: this.state.tabData[newTab - 1] });

    if (newTab == 1) {
      right = false;
      left = true;
    }

    if (newTab == 2 || newTab == 3) {
      right = false;
      left = false;
    }

    this.setState({ leftDisabled: left, rightDisabled: right });
  };

  handleRightClick() {
    console.log('came to right click...');
    var newTab = this.state.selectedTab.id + 1;
    var right = this.state.rightDisabled, left = this.state.leftDisabled;

    if (newTab > 4) { newTab = 4 };
    this.setState({ selectedTab: this.state.tabData[newTab - 1] });

    if (newTab == 4) {
      right = true;
      left = false;
    }

    if (newTab == 2 || newTab == 3) {
      right = false;
      left = false;
    }

    this.setState({ leftDisabled: left, rightDisabled: right });
  };


  async componentWillMount() {

    var link = document.createElement('meta');
    link.setAttribute('name', 'keywords');
    link.content = "pharmaceutical company, affordable medicine, pharmaceutical services, pharmaceutical industry, drugs manufacturer, dr reddys";
    document.getElementsByTagName('head')[0].appendChild(link);

    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/landing_page_news?include=field_file&&sort=-field_date_for_order2&&page[limit]=10", })
      .then((res) => {
        var temp = [];
        var imgPathsObj = {};
        if (res.data?.included) {
          for (var imgObj of res.data.included) {
            imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
          }
        }
        for (let news of res.data.data) {
          if (news.relationships.field_file.data === null) {
            temp.push({
              id: news.id,
              contentId: '1',
              fileBool: false,
              title: news.attributes.title,
              content: news.attributes.field_content ? news.attributes.field_content.processed : "",
              sourceName: news.attributes.field_source_name,
              publishDate: news.attributes.field_date_and_time,
              sourceUrl: news.attributes.field_source_url ? news.attributes.field_source_url.uri : "",
              yearCategory: news.attributes.field_landing_news_year,
              description: news.attributes.field_short_description,
            })
          } else {
            temp.push({
              fileBool: true,
              title: news.attributes.title,
              description: news.attributes.field_short_description,
              fileUrl: config.serverUrl.imageBase + imgPathsObj[news.relationships.field_file.data.id]
            })
          }
        }
        if (temp.length > 20) {
          temp.length = 20;
        }
        this.setState({ newsData: [...temp] }, () => {
          this.getArticleData();
        })
        console.log(res, "-=-=-=-", [...temp]);
      })
      .catch((err) => {
        this.getArticleData();
        console.log(err, "-=-=-=  ERR")
      })
  }


  getTabContent(selectedTab) {

    switch (selectedTab.id) {
      case 1: return (
        <>
          <p ref={(el) => {
            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
              if (isTab) {
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
              } else {
                el.style.setProperty('line-height', '24px', 'important');
                el.style.setProperty('font-size', '14px', 'important');
              }
            }
          }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
            Reducing Carbon Emissions
          </p>
          <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: '14px', fontWeight: 300 }}>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>100% renewable power by 2030</li>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Carbon neutrality in our direct operations (scope 1 and 2 emissions) by 2030</li>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>12.5% reduction in indirect carbon emissions (Scope 3 emissions) by 2030</li>
          </ul>
          <p ref={(el) => {
            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
              if (isTab) {
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
              } else {
                el.style.setProperty('line-height', '24px', 'important');
                el.style.setProperty('font-size', '14px', 'important');
              }
            }
          }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
            Water Positivity
          </p>
          <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: '14px', fontWeight: 300 }}>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Become water positive by 2025</li>
          </ul>
        </>
      )

      case 2: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Access
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: '14px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Serve over 1.5  billion patients by 2030</li>
        </ul>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Affordability
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: '14px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>25% of new launches to be first to market by 2027</li>
        </ul>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Innovation
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: '14px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>3 innovative products improving standard of treatment every year</li>
        </ul>
      </>)
      case 3: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Equity, Diversity and Inclusion
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: '14px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>At least 35% women in senior leadership (3x from current baseline) by 2030</li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Gender parity for organisation by 2035</li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>3% of our workforce to be persons with disabilities by 2030</li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Ensure 100% living wages for our on-premise extended workforce by 2025</li>
        </ul>
      </>)

      case 4: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Enhancing trust with our stakeholders
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: '14px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Highest standards of compliance and ethics backed by robust corporate governance</li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Progressively enhance disclosure on our ESG progress to reach top quartile by 2025</li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>100% of our strategic suppliers to be compliant with our internal ESG framework by 2030</li>
        </ul>
      </>)
      default: return (<div></div>)
    }
  }

  getTabImage(selectedTab) {

    switch (selectedTab.id) {
      case 1: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/esg_goals_img1.png" : "/images/esg_goals_img1.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 2: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/shashank-thapa-MkPINODL-Tw-unsplash.png" : "/images/shashank-thapa-MkPINODL-Tw-unsplash.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 3: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/_DSC2209-Edit.png" : "/images/_DSC2209-Edit.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 4: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/pexels-karolina-grabowska-7875996.png" : "/images/pexels-karolina-grabowska-7875996.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      default: return (<div></div>)
    }
  };



  getArticleData() {
    var tempObj = {};
    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/landingpagearticles?sort=-created&&include=field_thumbnail,field_coverimage", })
      .then((res) => {
        const { data, included } = res.data;
        var section1 = [];
        var section2 = [];
        var section3 = [];
        var imgPathsObj = {};
        for (var imgObj of included) {
          imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
        }
        for (var item of data) {
          tempObj = {
            id: item.id,
            contentId: '2',
            title: item.attributes.title,
            coverImage: item.relationships.field_coverimage.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_coverimage.data.id] : "",
            thumbnail: item.relationships.field_thumbnail.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_thumbnail.data.id] : "",
            description: item.attributes.field_description ? item.attributes.field_description.processed : "",
            content: item.attributes.field_content ? item.attributes.field_content.processed : "",
          }
          if (item.attributes.field_articlecategory === "Landing-Section-1") {
            section1.push(tempObj);
          } else if (item.attributes.field_articlecategory === "Landing-Section-2") {
            section2.push(tempObj);
          }
          else if (item.attributes.field_articlecategory === "Landing-Section-3") {
            section3.push(tempObj);
          }
        }
        console.log({
          section1, section2, section3
        })
        this.setState({
          articleData: {
            section1, section2, section3
          }
        }, () => {
          this.loadScript();
        })
      }).catch((Err) => {
        this.loadScript();
        console.log(Err, "-=-=-=  ERR")
      })
  }

  loadScript() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60952b8da0e26514531fde11'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        console.log("CDM  =-=---=   ")
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true }, () => {
          window.scrollTo({ top: window.scrollY - 1, left: 0, });
          window.scrollTo({ top: window.scrollY + 1, left: 0, });
        })
      }
      return active.isAsync ? next : loading
    }))

  }


  // LeftArrow() {
  //   const { isFirstItemVisible, scrollPrev } =
  //     React.useContext(VisibilityContext);

  //   return (
  //     <div>A</div>
  //     // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
  //     //   Left
  //     // </Arrow>
  //   );
  // }

  RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: 1 === 2 ? 'none' : 'auto', marginTop: '-15px', top: 0 }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={() => scrollNext()}>
        {
          1 === 2 ?
            <>
              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
            </>
            :
            <>
              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
            </>
        }
      </div>);
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    const [, ...exceptFirstNewsData] = this.state.newsData;

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd3\"] {opacity:0;display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd0\"] {height:100%;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd8\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64cccf\"] {opacity:0;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}}


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar,
            		.delivering-health-wrapper::-webkit-scrollbar,
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }

            .carousel-slider {
                width: 100%;
                height: 100vh;
            }

            .carousel .control-dots {
              bottom: 22% !important;
            }

            @media only screen and (max-width: 768px) {
              .carousel .control-dots {
                bottom: 20vh !important;
                margin-left: -18px;
              }

              .carousel .control-dots .dot {
                width: 12px !important;
                height: 12px !important;
                box-shadow: none;
                z-index: 99999;
              }
            }

            .carousel .control-dots .dot {
              width: 15px !important;
              height: 15px !important;
              box-shadow: none;
              z-index: 99999;
            }

            .carousal-image-overlay {
              position: absolute;
              left: 0;
              top: 50%;
              width: 100%;
              height: 50vh;
              background: transparent linear-gradient(180deg, #FFFFFF00 0%, #0F0F0F00 0%, #000000C9 100%) 0% 0% no-repeat padding-box !important;
            }

            .af-view .af-class-time-line-marker:before {
              height: 240px;
              top: -240px;
            }


        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span className="af-view">
          <div>
            <MenuSection className="af-class-transparent" />

            {/* <HeaderBanner data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} /> */}
            { /* <div data-w-id="bd96a6f0-b121-df05-c8fd-9a78b0c67316" className="af-class-about-hero-circle-wrapper">
              <a href='https://www.evoting.nsdl.com' target="_blank">
                <div className="af-class-section af-class-hero-about-us"></div>
              </a>
            </div> */}

            {
              !window.matchMedia('(max-width: 767px)').matches
                ?
                <div id="main" className="af-class-section" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, paddingTop: '150px', width: '100%' }}  >
                  { /* <Carousel showIndicators={false} showArrows={false} showStatus={false} showThumbs={false} autoPlay={false} infiniteLoop={false} dynamicHeight={false}>
                  <div>
                      <a href="https://www.evoting.nsdl.com/" target="_blank" style={{ display: "block", height: "100%" }}>
                        <img src="/images/39-annual-general-meeting-banner.jpg" loading="lazy" />
                        <div className='carousal-image-overlay' />
                      </a>
                    </div>
                  </Carousel> */ }
                  <Carousel showIndicators={true} showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} dynamicHeight={false}>
                  <div>
                      <img src="/images/42.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/41.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/40.jpeg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/39.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/38.jpeg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/37.jpeg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/34.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>

                    { /* <div>
                      <a href='https://forms.office.com/r/RUJXCDEGQ5' target="_blank" style={{ display: "block", height: "100%" }}>
                        <img src="/images/30.png" loading="lazy" />
                        <div className='carousal-image-overlay' />
                      </a>
                </div> 
                    <div>
                      <img src="/images/29.jpg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div> */ }
                    { /* <div>
                      <img src="/images/28.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                </div> */}
                    { /* <div>
                      <img src="/images/27.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                </div> */ }
                    <div>
                      <img src="/images/25.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    { /* <div>
                      <img src="/images/23.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
              </div> */ }
                    { /* <div>
                      <img src="/images/15.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <a href="https://www.drreddys.com/cms/cms/sites/default/files/2023-02/Dr.%20Reddy's%20press%20release%20-%20Bloomberg%20GEI%20&%20S&P%20SY'23.pdf" target="_blank" style={{ display: "block", height: "100%" }}>
                        <img src="/images/11.png" loading="lazy" />
                        <div className='carousal-image-overlay' />
                      </a>
                    </div>
                    <div>
                      <img src="/images/1.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
            </div> */ }
                    <div>
                      <img src="/images/3.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                  </Carousel>
                  <h1 style={{ textAlign: 'right', zIndex: 99999, position: 'absolute', left: '85%', bottom: window.matchMedia('(max-width: 820px)').matches ? '5%' : '15%', }} onClick={() => {
                    window.location.href = "#about-us";
                  }} >
                    <Lottie options={defaultOptions}
                      height={150}
                      width={150}
                    />
                  </h1>
                </div>
                :
                <div id="main" className="af-class-section" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, width: '100%', maxHeight: '100vh' }}  >
                  { /* <Carousel showIndicators={false} preventMovementUntilSwipeScrollTolerance={false} swipeScrollTolerance={10} showArrows={false} showStatus={false} showThumbs={false} autoPlay={false} infiniteLoop={false} dynamicHeight={false}>
                    <div>
                      <a href="https://www.evoting.nsdl.com/" target="_blank" style={{ display: "block", height: "100%" }}>
                        <img src="/images/39-annual-general-meeting-banner-mobile.jpg" loading="lazy" />
                        <div className='carousal-image-overlay' />
                      </a>
                    </div>
                </Carousel> */ }
                  <Carousel showIndicators={true} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={10} showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} dynamicHeight={false}>
                  <div>
                      <img src="/images/42m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/41m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/40m.jpeg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/39m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/38m.jpeg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/37m.jpeg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img src="/images/34m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    { /* <div>
                      <img src="/images/30m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
              </div> 
                    <div>
                      <img src="/images/29m.jpg" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div> */ }
                    { /* <div>
                      <img src="/images/28m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
              </div> */ }
                    { /* <div>
                      <img src="/images/27m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
              </div> */ }
                    <div>
                      <img src="/images/25m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    { /* <div>
                      <img src="/images/23m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
            </div> */ }
                    { /* <div>
                      <img src="/images/22m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                      </div> */ }
                    { /* <div>
                      <img src="/images/15m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <a href="https://www.drreddys.com/cms/cms/sites/default/files/2023-02/Dr.%20Reddy's%20press%20release%20-%20Bloomberg%20GEI%20&%20S&P%20SY'23.pdf" target="_blank" style={{ display: "block", height: "100%" }}>
                        <img src="/images/11m.png" loading="lazy" />
                        <div className='carousal-image-overlay' />
                      </a>
                    </div>
                    <div>
                      <img src="/images/1m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div> */ }
                    <div>
                      <img src="/images/3m.png" loading="lazy" />
                      <div className='carousal-image-overlay' />
                    </div>
                  </Carousel>
                  <h1 style={{ zIndex: 99999, position: 'absolute', left: '65vw', right: 0, top: window.matchMedia('(max-width: 820px)').matches ? '71vh' : 0, }} onClick={() => {
                    window.location.href = "#about-us";
                  }} >
                    <Lottie options={defaultOptions}
                      height={120}
                      width={120}
                    />
                  </h1>
                </div>
            }

            {/* Vision Statement  */}


            {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div id="about-us" data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-section af-class-z-index-2" >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }}>
                {/* <div  style={{  }} > */}
                <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100%', height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'unset' }}>
                  <div style={{ paddingLeft: '100px' }}>
                    {/* <h2 ref={(el)=>{
                                if(el && isTab){
                                  el.style.setProperty('font-size','36px','important')
                                  el.style.setProperty('margin-bottom','20px','important')
                                }
                              }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
                    <p ref={(el) => {
                      if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                        if (isTab) {
                          el.style.setProperty('font-size', '20px', 'important');
                          el.style.setProperty('line-height', '32px', 'important');
                          el.style.setProperty('max-width', '100%', 'important');
                        } else {
                          el.style.setProperty('line-height', '28px', 'important');
                        }
                      }
                    }} className="af-class-paragraph af-class-_450" style={{ maxWidth: '100%', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >We are committed to providing access to affordable and innovative medicines, driven by our purpose of ‘Good Health Can’t Wait’. <br /> <br /> Our products and services are spread across our core businesses of Active Pharmaceutical Ingredients (API), generics, branded generics, biosimilars and over-the-counter pharmaceutical products around the world. We work towards meeting unmet patients needs in the areas of gastro-enterology, cardiovascular, diabetology, oncology, pain management and dermatology. We are investing in businesses of the future including drug discovery, clinically-differentiated assets and digital healthcare.</p>
                    <a href="/business-and-capabilities" target="_blank" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                      <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                      <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >LEARN&nbsp;MORE</div>
                    </a>
                  </div>
                </div>
                <div ref={(el) => {
                  if (el && isTab) {
                    el.style.setProperty('height', '280px', 'important')
                  }
                }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'auto' : '100%', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100%', marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '20px' }}>
                  <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/mobile_annual_report.png" : "/images/annualreport_2021.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: 'auto', width: '100%', objectFit: 'cover', marginBottom: '20px' } : { height: '100%', width: '100%', objectFit: 'cover' }} />
                </div>
                {/* </div> */}
              </div>
            </div>
            }

            {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div id="about-us"
              data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-section af-class-z-index-2"
              style={{ minHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100vh' }}>
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <div style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
                  <div className="af-class-scroll-video-block" style={{
                    padding: 0,
                    width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%",
                    maxHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '370px'
                  }}>
                    <div>
                      {/* <h2 ref={(el)=>{
                                if(el && isTab){
                                  el.style.setProperty('font-size','36px','important')
                                  el.style.setProperty('margin-bottom','20px','important')
                                }
                              }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
                      <p ref={(el) => {
                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                          if (isTab) {
                            el.style.setProperty('font-size', '20px', 'important');
                            el.style.setProperty('line-height', '32px', 'important');
                            el.style.setProperty('max-width', '100%', 'important');
                          } else {
                            el.style.setProperty('line-height', '24px', 'important');
                            // el.style.setProperty('padding', '24px', 'important');
                          }
                        }
                      }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && (isTab ? '16px' : '14px') }} > We serve customers around the world through our businesses of Active Pharmaceutical Ingredients (API), generics, branded generics, biosimilars and over-the-counter pharmaceutical products. <br /> <br /> In our branded businesses we focus on patient needs in the areas of gastro-enterology, cardiovascular, diabetology, oncology, pain management and dermatology.We are actively developing a pipeline of novel small molecules in immuno-oncology.We are working on a pipeline of differentiated products that improve existing therapeutic agents.We are also investing in emerging areas such as digital therapeutics, cell and gene therapies, among others.</p>
                      <a href="/business-and-capabilities" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                        <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >LEARN&nbsp;MORE</div>
                      </a>
                    </div>
                  </div>
                  <div ref={(el) => {
                    if (el && isTab) {
                      el.style.setProperty('height', '280px', 'important')
                    }
                  }} className='af-class-scroll-video-block' style={{
                    height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '40vh' : 'auto',
                    width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "45%",
                    marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '20px' : "8%",
                    // padding: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px',
                    paddingBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '20px',

                  }}>
                    <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/mobile_annual_report.png" : "/images/mobile_annual_report.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover', marginBottom: '20px' } : { height: '100%', width: '100%', objectFit: 'cover' }} />
                  </div>
                </div>
              </div>
            </div>
            }


            {/* {
              window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                ?
                <ImageSliderMobile data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
                :
                <ImageSlider data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
            } */}

            {/* <div style={{ height: '400vh', width: '100vw' }} > */}
            {/* <div style={{ minHeight: '400vh', zIndex: 1, position: 'absolute' }} > */}
            {/* <div style={{ position: 'sticky', top: '0%', minHeight: '100vh', zIndex: 1 }} > */}
            {/* <div className='af-class-section'>
              {
                this.state.articleData.section1 && this.state.articleData.section1.length !== 0 && <ImageSliderGsap data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
              }
            </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}

            {/* <div className="af-class-home-white-cover" />
              <div className="af-class-section af-class-tall-blocks">
                <div className="af-class-container af-class-tall-bloks w-container">
                  <div className="w-dyn-list">
                    <div role="list" className="af-class-_3-col-grid af-class-tall-bloks w-dyn-items">
                      <div role="listitem" className="w-dyn-item">
                        <div data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64cccf" className="af-class-tall-block">
                          <div data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64ccd0" className="af-class-tall-block-text-content">
                            <h2 className="af-class-h2-section-heading af-class-less-line-height" />
                            <div data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64ccd3" className="af-class-tall-block-sec-heading" />
                          </div>
                          <a href="#" className="af-class-tall-block-link w-inline-block">
                            <div className="af-class-tall-block-circle" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64ccd8" alt className="af-class-white-arrow" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="w-dyn-empty">
                      <div>No items found.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="af-class-section af-class-delivering-health">
              <div className="af-class-container af-class-sticky w-container">
                <div className="af-class-delivering-health-wrapper">
                  <div className="af-class-scroll-video-block">
                    <div data-poster-url="https://uploads-ssl.webflow.com/602c9aff04a981415df03c05/6050242e2eb7482ad519d533_Delivering good health section GIFs-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/60952b8da0e265187c1fde0e/60952b8da0e26568991fde4c_Delivering%20good%20health%20section%20GIFs-transcode.mp4,https://uploads-ssl.webflow.com/60952b8da0e265187c1fde0e/60952b8da0e26568991fde4c_Delivering%20good%20health%20section%20GIFs-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-delivering-health-video w-background-video w-background-video-atom"><video autoPlay loop style={{backgroundImage: 'url("https://uploads-ssl.webflow.com/602c9aff04a981415df03c05/6050242e2eb7482ad519d533_Delivering good health section GIFs-poster-00001.jpg")'}} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src="https://uploads-ssl.webflow.com/60952b8da0e265187c1fde0e/60952b8da0e26568991fde4c_Delivering%20good%20health%20section%20GIFs-transcode.mp4" data-wf-ignore="true" />
                        <source src="https://uploads-ssl.webflow.com/60952b8da0e265187c1fde0e/60952b8da0e26568991fde4c_Delivering%20good%20health%20section%20GIFs-transcode.webm" data-wf-ignore="true" />
                      </video>
                      <div className="af-class-stickey-video-text-overlay">
                        <h2 data-w-id="6a2b6dfa-c236-dd6d-73b4-b8929acadc19" style={{opacity: 0}} className="af-class-h2-section-heading af-class-title af-class-left">Delivering good health to all</h2>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-scroll-text-wrapper">
                    <div className="w-dyn-list">
                      <div role="list" className="w-dyn-items">
                        <div role="listitem" className="w-dyn-item">
                          <div className="af-class-scroll-text-block">
                            <div className="af-class-scroll-text-div">
                              <div className="af-class-scroll-animation-box">
                                <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" />
                                <p className="af-class-paragraph" />
                                <a href="#" className="af-class-link-arrow af-class-left-side w-inline-block">
                                  <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-dyn-empty">
                        <div>No items found.</div>
                      </div>
                    </div>
                    <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                      <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                          <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b1c" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-delivering-health-slider-heading">Braving a snowstorm with passion and persistence</div>
                              <div>A surprise winter storm brought the southern states of the US to a standstill, blocking access to key medicines. But we did not let that get in the way of keeping our promise.</div>
                              <a href="https://dr-reddy.webflow.io/articles/braving-a-snowstorm-with-passion-and-persistence" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                          <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b24" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-delivering-health-slider-heading">Working together to fight a pandemic</div>
                              <div>Making good quality drugs available to everyone in Indonesia in double-quick time required attention to detail and teamwork.</div>
                              <a href="https://dr-reddy.webflow.io/articles/working-together-to-fight-a-pandemic" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                          <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b2c" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-delivering-health-slider-heading">Helping patients in Germany manage their diseases better</div>
                              <div>Our German subsidiary betapharm has been distributing guidebooks that educate patients for almost two decades.</div>
                              <a href="https://dr-reddy.webflow.io/articles/helping-patients-in-germany-manage-their-diseases-better" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                          <div data-w-id="58024180-f3f9-8b57-a388-78a79b1e0393" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-delivering-health-slider-heading">How becoming a visual-ally made me see life in a new light</div>
                              <div>Long-distance running taught Anupama many things, but it took running with a visually challenged partner for her learn its most valuable lesson.</div>
                              <a href="https://dr-reddy.webflow.io/articles/how-becoming-a-visual-ally-made-me-see-life-in-a-new-light" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b44" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                          <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b48" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                          <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b4a" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" />
                      </div>
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                    <div className="af-class-drag-progress-fill af-class-fifty" />
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div data-w-id="2d2b52c6-c2a0-6a30-2f22-a60086d2a0d4" className="af-class-section af-class-vision"> */}
            <div className="af-class-section" style={{ padding: 0, margin: 0 }}>
              {/* <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      isTab
                        ?
                        <h1 className="af-class-h2-section-heading af-class-title af-class-vision" style={{ fontSize: '64px', lineHeight: '80px', marginLeft: '12vw', marginRight: '12vw' }} >A vision that <br /> was born in <br /> India and now <br /> spans the globe</h1>
                        :
                        <h1 className="af-class-h2-section-heading af-class-title af-class-vision">A vision that was born in India and now spans <br /> the globe</h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-vision">A vision that was born in India and now spans the globe</h1>
                  }
                </div>
              </div> */}

              <div className="af-class-vision-overview" style={{ top: '0%', position: 'relative' }}>
                <div className="af-class-container w-container">
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      <p ref={(el) => {
                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                          if (isTab) {
                            el.style.setProperty('font-size', '24px', 'important');
                            el.style.setProperty('line-height', '40px', 'important');
                            el.style.setProperty('max-width', '400px', 'important');
                          } else {
                            el.style.setProperty('line-height', '28px', 'important');
                            el.style.setProperty('font-size', '16px', 'important');
                            el.style.setProperty('padding-left', '10px', 'important');
                            el.style.setProperty('padding-right', '10px', 'important');
                          }
                        }
                      }} className="af-class-paragraph af-class-_382" style={{ textAlign: 'center', marginBottom: '30px' }} >We started in 1984 with a modest investment and a bold vision. Today, with research and development centres, manufacturing facilities and commercial presence across the globe, we serve over half a billion patients worldwide. We aspire to <br />triple our reach and touch over 1.5 billion patients by 2030.</p>
                      :
                      // <p className="" style={{textAlign: "center"}} >We started in 1984 with a modest investment, 20 employees and a bold vision. Today, we have <br/>research and development centres, manufacturing facilities or a commercial presence in 66 <br/>countries. Our 24,000-plus employees work towards one clear purpose: to accelerate access to <br/>affordable and innovative medicines.</p>
                      <p className="" style={{ textAlign: "center" }} >We started in 1984 with a modest investment and a bold vision.<br /> Today, with research and development centres, manufacturing facilities and commercial <br />presence across the globe, we serve over half a billion patients worldwide. We aspire to <br />triple our reach and touch over 1.5 billion patients by 2030.</p>
                  }
                  <div className="af-class-vision-overview-wrapper">
                    {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ?
                      <>
                        <div id="w-node-c364a23f-571a-598b-86c0-e504112c58d6-531fde11" data-w-id="c364a23f-571a-598b-86c0-e504112c58d6" className="w-layout-grid af-class-vision-numbers-grid">
                          <div className="af-class-countries">
                            <div className="af-class-big-number-wrapper">
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-7">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-6">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                            </div>
                            <div>countries</div>
                          </div>
                          <div className="af-class-nationalities-block">
                            <div className="af-class-big-number-wrapper">
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-5">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-3">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                            </div>
                            <div>nationalities</div>
                          </div>
                          <div className="af-class-manufacturing-facilities-block">
                            <div className="af-class-big-number-wrapper" style={{ alignItems: "baseline" }}>
                              USD
                              <div className="af-class-numerical-digit-block af-class-thoda-right">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-3">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <span style={{ fontSize: "80px" }}>.</span>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-3">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-5">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              bn
                            </div>
                            <div style={{ marginTop: '-6px' }}>Revenue (As of FY '24)</div>
                          </div>
                          <div className="af-class-rd-facilities-block" style={{ paddingLeft: '30px' }}>
                            <div className="af-class-big-number-wrapper" style={{ alignItems: "baseline" }}>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-9">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <span style={{ fontSize: "80px" }}>.</span>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-7">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              %
                            </div>
                            <div style={{ paddingTop: '8px' }}>EBITDA (As of FY '25)</div>
                          </div>
                          <div className="af-class-rd-facilities-block" style={{ gridColumn: 'span 2' }}>
                            <div className="af-class-big-number-wrapper" style={{ alignItems: "baseline" }}>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-6">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <span style={{ fontSize: "80px" }}>,</span>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-0">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-0">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-0">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <span style={{ fontSize: "80px" }}>+</span>
                            </div>
                            <div>employees</div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {/* mobile and tab */}

                        <div data-w-id="39ac4978-e7d5-4ae9-9999-ebb2cb16d5ea" style={{ textAlign: 'center' }} className="w-layout-grid af-class-vision-numbers-grid af-class-mobile" >
                          <div>
                            <div className="af-class-big-number-wrapper-mobile">
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-7-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-4-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                            </div>
                            <div style={{ fontSize: '14px' }}>countries</div>
                          </div>
                          <div>
                            <div className="af-class-big-number-wrapper-mobile">
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-5-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-3-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                            </div>
                            <div style={{ fontSize: '14px' }}>nationalities</div>
                          </div>

                          <div className="af-class-rd-facilities-block">
                            <div className="af-class-big-number-wrapper-mobile" style={{ alignItems: "baseline" }}>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-9-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <span style={{ fontSize: "40px" }}>.</span>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-7-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              %
                            </div>
                            <div style={{ fontSize: '14px' }}>EBITDA <br />(As of FY '22)</div>
                          </div>

                          <div className="af-class-rd-facilities-block" style={{ gridColumn: 'span 3' }}>
                            <div className="af-class-big-number-wrapper-mobile" style={{ alignItems: "baseline" }}>
                              USD
                              <div className="af-class-numerical-digit-block-mobile af-class-thoda-right">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <span style={{ fontSize: "40px" }}>.</span>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-9-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-9-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              bn
                            </div>
                            <div style={{ fontSize: '14px' }}>Revenue (As of FY '23)</div>
                          </div>

                          <div className="af-class-rd-facilities-block" style={{ gridColumn: 'span 3' }}>
                            <div className="af-class-big-number-wrapper-mobile" style={{ alignItems: "baseline" }}>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-4-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <span style={{ fontSize: "40px" }}>,</span>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-8-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-3-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                              <div className="af-class-numerical-digit-block-mobile">
                                <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2-mobile">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                              </div>
                            </div>
                            <div style={{ fontSize: '14px' }}>employees</div>
                          </div>


                        </div>
                      </>

                    }
                  </div>
                </div>
              </div>
            </div>


            {/* <div className="af-class-section af-class-vision-numbers">
              <div className="af-class-container w-container">
                <div data-w-id="39ac4978-e7d5-4ae9-b8d9-ebb2cb16d5ea" style={{ textAlign: 'center' }} className="w-layout-grid af-class-vision-numbers-grid af-class-mobile">
                  <div className="af-class-nationalities-block">
                    <div className="af-class-big-number-wrapper">
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-4">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-8">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                    </div>
                    <div>nationalities</div>
                  </div>
                  <div className="af-class-manufacturing-facilities-block">
                    <div className="af-class-big-number-wrapper">
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-3">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                    </div>
                    <div>manufacturing facilities</div>
                  </div>
                  <div>
                    <div className="af-class-big-number-wrapper">
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-4">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-2">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                    </div>
                    <div>countries</div>
                  </div>
                  <div className="af-class-rd-facilities-block">
                    <div className="af-class-big-number-wrapper">
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-0">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                      <div className="af-class-numerical-digit-block">
                        <div style={{ WebkitTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-numerical-9">0<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                      </div>
                    </div>
                    <div>R&amp;D facilities</div>
                  </div>
                </div>
              </div>
            </div> */}


            {/* good health can't wait  */}

            <div id="our-credo" className="af-class-section af-class-z-index-2" style={{}} >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <div className="af-class-delivering-health-wrapper" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
                  <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'unset' }}>
                    <div>
                      <h2 ref={(el) => {
                        if (el && isTab) {
                          el.style.setProperty('font-size', '36px', 'important')
                          el.style.setProperty('margin-bottom', '20px', 'important')
                        }
                      }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2>
                      <p ref={(el) => {
                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                          if (isTab) {
                            el.style.setProperty('font-size', '20px', 'important');
                            el.style.setProperty('line-height', '32px', 'important');
                            el.style.setProperty('max-width', '100%', 'important');
                          } else {
                            el.style.setProperty('line-height', '28px', 'important');
                          }
                        }
                      }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >Our purpose is to provide access to affordable and innovative medicines and healthcare solutions. Read more about our purpose, principles and values.</p>
                      <a href="/who-we-are" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                        <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >WHO&nbsp;WE&nbsp;ARE</div>
                      </a>
                    </div>
                  </div>
                  <div ref={(el) => {
                    if (el && isTab) {
                      el.style.setProperty('height', '280px', 'important')
                    }
                  }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '420px' : 'auto', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "8%" }}>
                    <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/goodhealth.png" : "/images/goodhealth.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
                  </div>
                </div>
              </div>
            </div>



            {/* <div data-w-id="19cc00fd-e1b3-336b-5837-c6cf2129d595" className="af-class-section af-class-vision"
              style={{ minHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '150vh' }}>
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  {
                    <>
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ?
                        <h2 data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7" className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ marginTop: '40px', marginBottom: '24px', fontSize: isTab ? '48px' : '36px' }} >Good Health <br />Can’t Wait</h2>
                        : <h2 data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7" className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ marginBottom: '40px', fontSize: '60px' }} >Good Health Can’t Wait</h2>
                      }
                      <p data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7" ref={(el) => {
                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                          if (isTab) {
                            el.style.setProperty('font-size', '24px', 'important');
                            el.style.setProperty('line-height', '40px', 'important');
                            el.style.setProperty('max-width', '70%', 'important');
                          } else {
                            el.style.setProperty('line-height', '30px', 'important');
                          }
                        }
                      }} className="af-class-paragraph af-class-_572 af-class-center" style={{
                        fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px',
                        maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '360px' : '600px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}>
                        Our purpose is to provide access to affordable and innovative medicines and healthcare solutions. Read more about our purpose, principles and values.</p>

                      <a data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7" href="/who-we-are"
                        className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block"
                        style={{ marginLeft: 'auto', marginRight: 'auto', justifyContent: 'center' }}>
                        <div className="af-class-link-circle af-class-white af-class-hide" />
                        {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <img src="/images/black-arrow.svg" loading="lazy" alt className="af-class-white-arrow" />}
                        <img src="/images/black-arrow.svg" loading="lazy" alt className="af-class-black-arrow af-class-hide"
                          style={{ marginLeft: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '-30px' }} />
                        <div className="af-class-link-arrow-slide-text af-class-right-to-arrow"
                          ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) el.style.setProperty('margin-left', '-15px', 'important');
                          }}
                          style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >LEARN&nbsp;MORE</div>
                      </a>
                    </>
                  }
                </div>
              </div>
            </div> */}


            {/* <div id="leadership" className="af-class-section" style={{
              backgroundColor: "rgb(255, 199, 184)", minHeight: '100vh'
            }}>
              <div className="af-class-values-section-title-block">
                <h2 className="af-class-h2-section-heading af-class-title af-class-values" style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} >Good Health Can’t Wait</h2>
                <p ref={(el) => {
                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    if (isTab) {
                      el.style.setProperty('font-size', '24px', 'important');
                      el.style.setProperty('line-height', '40px', 'important');
                      el.style.setProperty('max-width', '70%', 'important');
                    } else {
                      el.style.setProperty('line-height', '30px', 'important');
                    }
                  }
                }} className="af-class-paragraph af-class-_572" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px', maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '320px' }} >We provide access to affordable and innovative drugs because <br/> ‘Good Health Can’t Wait’. Learn more about how we live our purpose, principles and values through our work.</p>
                <a href="/who-we-are" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                  <div className="af-class-link-circle af-class-purple af-class-hide"/>
                  <img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                  <img src="/images/black-arrow.svg" loading="lazy" alt className="af-class-black-arrow af-class-hide" />
                  <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600' }} >WHO&nbsp;WE&nbsp;ARE</div>
                </a>
              </div>
            </div> */}


            {/* <div data-w-id="2d2b52c6-c2a0-6a30-2f22-a60086d2a0d4" className="af-class-section af-class-vision">
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container" style={{alignItems: 'center'}}>
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      isTab
                        ?
                        <h1 className="af-class-h2-section-heading af-class-title af-class-vision" style={{ fontSize: '64px', lineHeight: '80px', marginLeft: '12vw', marginRight: '12vw' }} >A vision that <br /> was born in <br /> India and now <br /> spans the globe</h1>
                        :
                        <h1 className="af-class-h2-section-heading af-class-title af-class-vision">A vision that was born in India and now spans <br /> the globe</h1>
                      :
                      <>
                        <h2 className="af-class-h2-section-heading af-class-title af-class-values" style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} >Good Health Can’t Wait</h2>
                        <p ref={(el) => {
                          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                            if (isTab) {
                              el.style.setProperty('font-size', '24px', 'important');
                              el.style.setProperty('line-height', '40px', 'important');
                              el.style.setProperty('max-width', '70%', 'important');
                            } else {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }
                        }} className="af-class-paragraph af-class-_572" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px', maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '320px' }} >We provide access to affordable and innovative drugs because <br/> ‘Good Health Can’t Wait’. Learn more about how we live our purpose, principles and values through our work.</p>
                        <a href="/who-we-are" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                          <div className="af-class-link-circle af-class-purple af-class-hide"/>
                          <img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                          <img src="/images/black-arrow.svg" loading="lazy" alt className="af-class-black-arrow af-class-hide" />
                          <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600' }} >WHO&nbsp;WE&nbsp;ARE</div>
                        </a>
                      </>

                  }
                </div>
              </div>
            </div> */}

            <div className="af-class-section af-class-transform-health-care" style={{
              minHeight: '100vh', height: 'auto',
              paddingTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '60px' : '60px',
              paddingBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '60px' : '180px',
              overflow: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'auto',
            }} >
              <div data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7" style={{ opacity: 0 }} className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading">Our core tenets</h2>
                <div className="af-class-drag-slider-wrapper af-class-non-slider">
                  <div className="af-class-slider-card"  >
                    <a data-w-id="9c94c1a1-83c6-7fad-3e88-66e44051ac99" href="/articles?4#c008be91-dbb4-4bf8-b4b2-e516d101eb80" className="af-class-slide-image-and-text-block w-inline-block">
                      <div data-w-id="9c94c1a1-83c6-7fad-3e88-66e44051ac99" className="af-class-slide-image-and-text-block w-inline-block" style={{ textDecoration: 'none' }} >
                        <div className="af-class-drag-slider-item-thumbnail"><img src="images/deep-science.png" loading="lazy" alt className="af-class-drag-slider-item-image-animation" /></div>
                        <div className="af-class-drag-slider-item-heading" >Pioneering work based on science</div>
                      </div>
                    </a>
                    <div style={{ height: '160px' }} >Our deep science capabilities form the bedrock of our work enabling us to innovate for patients. Our investment in complex chemistry and biology have led us to several firsts. In India, we were among the first to export APIs at scale and introduce many new molecules. We were the first in India to initiate novel drug discovery in the 1990s and to receive 180-day exclusivity from the USFDA in the 2000s. We focus on being first to market, helping us deliver on our promises.</div>
                    {/* <a href="https://dr-reddy.webflow.io/about-us#Journey-so-far" className="af-class-link-arrow af-class-left-side w-inline-block">
                      <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                    </a> */}
                  </div>
                  <div className="af-class-slider-card" >
                    <a data-w-id="f4a9580a-e7aa-d3e5-8445-ea87921fc6a8" href="/LifeAtDrReddys" className="af-class-slide-image-and-text-block w-inline-block">
                      <div data-w-id="f4a9580a-e7aa-d3e5-8445-ea87921fc6a8" className="af-class-slide-image-and-text-block w-inline-block" style={{ textDecoration: 'none' }}>
                        <div className="af-class-drag-slider-item-thumbnail"><img src="images/ground-group-growth-hands-461049.png" loading="lazy" alt className="af-class-drag-slider-item-image-animation" /></div>
                        <div className="af-class-drag-slider-item-heading">Progressive People Practices</div>
                      </div>
                    </a>
                    <div style={{ height: '160px' }} >We have pioneered practices such as ‘Self-Managed Teams’ in pharma. We develop leaders through our initiatives such as our ‘New Horizons Leadership Programme’. Our investment in people capabilities serves not just our company but also the industry. We have set bold goals for ourselves in our diversity, equity and inclusion journey. We aspire to create an environment in which people can realise their full potential through work and continuous learning.</div>
                    {/* <a href="businesses-products.html" className="af-class-link-arrow af-class-left-side w-inline-block">
                      <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                    </a> */}
                  </div>
                  <div className="af-class-slider-card" >
                    <a data-w-id="4e63d439-f158-9b1f-f8f2-d2d46bc5d31c" href="/investor" className="af-class-slide-image-and-text-block w-inline-block">
                      <div data-w-id="4e63d439-f158-9b1f-f8f2-d2d46bc5d31c" className="af-class-slide-image-and-text-block w-inline-block" style={{ textDecoration: 'none' }}>
                        <div className="af-class-drag-slider-item-thumbnail"><img src="images/home-page-image-21.png" loading="lazy" alt className="af-class-drag-slider-item-image-animation" /></div>
                        <div className="af-class-drag-slider-item-heading">Rigour in Governance</div>
                      </div>
                    </a>
                    <div style={{ height: '160px' }}>We were the first Asian pharma company outside Japan to list on the New York Stock Exchange in 2001. We are committed to the highest standards of compliance, ethics and quality in every activity that we undertake. We are consistently recognised by our stakeholders for our governance. We endeavour to set the bar high and maintain the trust our stakeholders repose in us. We continue to work on progressive and timely disclosures, transparent policies and processes.</div>
                    {/* <a href="science-research.html" className="af-class-link-arrow af-class-left-side w-inline-block">
                      <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                    </a> */}
                  </div>
                </div>


                <HomeSlider />


                {/* <div className="af-class-drag-slider-wrapper af-class-mobile-only af-class-with-red-border">
                  <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                    <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                      <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                        <div className="af-class-slide-div">
                          <div className="af-class-drag-slider-item-thumbnail"><img src="images/national-cancer-institute-O13g6-Gtb5o-unsplash.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                          <div className="af-class-drag-slider-item-heading">A legacy of scientific breakthroughs</div>
                          <div>Our foundation is in deep science, and we harness new-age processes to grow our portfolio and reach. Using critical advances in science and technology, we constantly innovate ways to live up to our purpose.</div>
                          <a href="#" className="af-class-link-arrow af-class-left-side w-inline-block">
                            <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                        <div className="af-class-slide-div">
                          <div className="af-class-drag-slider-item-thumbnail"><img src="images/digital-all-the-way.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                          <div className="af-class-drag-slider-item-heading">Digital all the way</div>
                          <div>Keeping pace with the times is not enough. We must be ready for the future and digitalisation is key to our business and sustainability goals. We continue to set new milestones as we adopt digital practices in all areas of our business.</div>
                          <a href="#" className="af-class-link-arrow af-class-left-side w-inline-block">
                            <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                        <div className="af-class-slide-div">
                          <div className="af-class-drag-slider-item-thumbnail"><img src="images/shutterstock_707493547.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                          <div className="af-class-drag-slider-item-heading">Innovation in business</div>
                          <div>As a global pharmaceutical company, we retain our distinct competitive edge through business-centric innovations. Our capability-based approach fuels our growth. This, in turn, brings us closer to accelerating access to affordable medicines to patients worldwide.</div>
                          <a href="#" className="af-class-link-arrow af-class-left-side w-inline-block">
                            <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div data-w-id="cb5f2404-208e-f18b-0ba2-f7f868bea540" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                      <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div data-w-id="cb5f2404-208e-f18b-0ba2-f7f868bea544" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                      <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="cb5f2404-208e-f18b-0ba2-f7f868bea546" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div className="af-class-slide-nav w-slider-nav w-round" />
                  </div>
                </div>
                <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                  <div className="af-class-drag-progress-fill af-class-fifty" />
                </div> */}
              </div>
            </div>

            {/* <div id="sustainability" data-w-id="faef8a71-a975-b9d7-3c09-ee032bc46e56" className="af-class-section af-class-always-innovating" style={{height: '100vh'}}>
              <div className="af-class-always-innovating-heading-block" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'unset' }} >
                <h1 data-w-id="faef8a71-a975-b9d7-3c09-ee032bc46e59" style={{ opacity: 0, maxWidth: '100%', textAlign: 'center', marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '60px' : '36px' : '80px' }} className="af-class-title af-class-become-the-best af-class-h2-section-heading" >Transforming <br/> healthcare to build a <br/> sustainable future</h1>
              </div>
            </div> */}


            {/* <div id="commitment" className="af-class-section" data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7" style={{
              backgroundColor: "rgb(255, 255, 255)", minHeight: '100vh'
            }}>
             <div className="af-class-container w-container">

             { window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ?
                <>
                <p ref={(el) => {
                    if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                      if (isTab) {
                        el.style.setProperty('font-size', '24px', 'important');
                        el.style.setProperty('line-height', '40px', 'important');
                        el.style.setProperty('max-width', '70%', 'important');
                      } else {
                        el.style.setProperty('line-height', '24px', 'important');
                        el.style.setProperty('padding-left', '25px', 'important');
                        el.style.setProperty('padding-right', '25px', 'important');
                      }
                    }
                  }}  style={{textAlign: 'center', width: '100%', fontColor: '#000000', fontSize: '16px'}} >
                    Our commitment to environment, social and governance is the core of our purpose and strategy, continuously driven by our value of sustainability because, Good Health Can’t Wait.</p>
                  <img style={{maxWidth: '80%', margin: '24px', marginLeft: '30px'}} src="/images/creds_mobile.png" loading="lazy" alt />
                </>
                :
                <>
                  <p style={{ paddingLeft: '25%', paddingRight: '25%', textAlign: 'center', width: '100%', fontColor: '#000000'}} >
                    Our commitment to environment, social and governance is the core of <br/>our purpose and strategy, continuously driven by our value of <br/>sustainability because, Good Health Can’t Wait.</p>
                <img style={{marginTop: '70px'}} src="/images/Creds1.jpg" loading="lazy" alt />
                </>
              }
              </div>
            </div> */}


            {/* <section id="Journey-so-far" data-w-id="8b095fb5-ee28-3b2f-8989-b5a8f5ac8121" className="af-class-section af-class-journey-section"
            style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100vh' :  '110vh',
                    minHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100vh': '110vh',
                    backgroundColor:"#EDEDED",
                    paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '240px',
                    display: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&  'flex',
                    alignItems: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&  'center',
                     }}>
              <div className="af-class-container w-container">
                {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div style={{ borderBottom: '1px solid #ff5046', marginBottom: '20px',
                 paddingBottom: '20px',
                 textUnderlineOffset: '10px', }} >
                  <h3 className="af-class-h3-heading" style={{ fontSize: '16px', fontWeight: 'bold', textTransform: 'none', margin: 0, marginBottom: '2 0px' }} >20 YEARS OF LEADING THE INDUSTRY IN SUSTAINABILITY</h3>
                </div>}
                <div className="af-class-timeline-wrapper">
                  <div className="af-class-time-line-marker">
                    <div data-w-id="205462a9-d649-00ae-41e3-d53ce1b40472" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-timeline-dot" />
                  </div>
                  <div className="af-class-years-block" style={{ position: "sticky" }} >

                    <div data-w-id="fc3812a5-12f6-a020-8989-2b46ddd2e2bc" className="af-class-year-text">
                      <div data-w-id="99d70436-c84d-3520-e143-f088eb5a9211" className="af-class-timeline-dot af-class-mobile" />
                      <div data-w-id="562362d4-bfb6-b4cf-085d-d5a368f5c814" style={{ fontWeight: 'bold' }} >2004</div>
                      <div data-w-id="aa62f39b-59a8-be68-5678-77b179e6571e" style={{ width: '0%' }} className="af-class-year-underline" />
                    </div>

                    <div data-w-id="0ccdee18-fa19-a667-8989-32cded283602" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2007</div>
                      <div data-w-id="7f63f200-4735-055f-ef29-ec1de8b73d69" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>

                    <div data-w-id="8684a292-2004-4544-8989-730b2f4dcaf8" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2010</div>
                      <div data-w-id="67293bb8-4d73-a363-87cf-9350b4573968" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>

                    <div data-w-id="319689f1-0471-7ef1-8989-0d62dbab72b9" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2013</div>
                      <div data-w-id="3925ae23-4a5d-89ae-c7f0-b8369e164a4e" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                    <div data-w-id="d5c6889c-6c45-b7df-8989-d9c590d72ece" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2016</div>
                      <div data-w-id="8408ad78-cf8c-a7a3-ec79-852199b2e9f1" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                    <div data-w-id="feb7428a-c74e-c8f9-8989-9fef637d7ff3" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2020</div>
                      <div data-w-id="ab8118c3-5d0c-f14a-7820-4f33a90b67b6" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                    <div data-w-id="0976f596-837e-b84d-8989-2d938ba4c56c" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2022</div>
                      <div data-w-id="6b1b712b-9d3d-b2ce-aae3-2a17d1f1d5b3" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                  </div>

                  <div className="af-class-year-numerical-wrapper" style={{ position: "sticky" }}>
                    <div className="af-class-year-digit">
                      <div data-w-id="0b4d8531-0a89-fecc-d301-c36927da109e" className="af-class-year-numerical-1" style={{ textAlign: 'right' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                    <div className="af-class-year-digit">
                      <div data-w-id="b4bf86e9-b4b9-8a9b-b938-62dcc59edabe" className="af-class-year-numerical-2" style={{ textAlign: 'left' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                    <div className="af-class-year-digit af-class-bottom">
                      <div data-w-id="85d68a56-476f-7846-384c-e8278098bfbc" className="af-class-year-numerical-3" style={{ textAlign: 'right' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                    <div className="af-class-year-digit">
                      <div data-w-id="d93819cf-ea4b-1cfb-63c1-d780a0a254ca" className="af-class-year-numerical-4" style={{ textAlign: 'left' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                  </div>

                  <div data-w-id="4faef0df-bc8a-51fc-6392-217069a7b600" className="af-class-yearly-content-wrapper">
                    {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading" style={{marginTop: '20px', marginBottom: '10px'}}>20 YEARS OF LEADING THE INDUSTRY IN SUSTAINABILITY</h3>}

                    <div className="af-class-year-content-block af-class-_1">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2004</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{fontSize: '24px', lineHeight: '30px', marginTop: '20px'}}>First Sustainability Report</div>

                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                             Dr. Reddy’s began voluntary disclosures on social and environmental aspects.
                            </p>
                            <br />
                          </>
                          :
                          <>
                          <div className="af-class-timeline-heading" style={{maxWidth: '520px', marginTop: '20px'}}>First Sustainability Report</div>
                          <p ref={(el) => {
                               if (el) {el.style.setProperty('line-height', '30px', 'important');}
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300'}} >
                              Dr. Reddy’s began voluntary disclosures on social and environmental aspects.
                          </p>
                          </>
                      }
                    </div>

                    <div className="af-class-year-content-block af-class-_2">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2007</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{fontSize: '24px', lineHeight: '30px', marginTop: '20px'}}>Sustainability statement</div>
                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              Covering product responsibility, transparency,
                              operational excellence, human dignity, equal opportunity, and community development
                            </p>
                            <br />
                          </>
                          :
                          <>
                          <div className="af-class-timeline-heading" style={{maxWidth: '520px', marginTop: '20px'}}>Sustainability statement</div>
                          <p ref={(el) => {
                               if (el) {el.style.setProperty('line-height', '30px', 'important');}
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300'}} >
                              Covering product responsibility, transparency, operational excellence, human dignity, equal opportunity, and community development
                          </p>
                          </>

                     }
                    </div>

                    <div className="af-class-year-content-block af-class-_3">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2010</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{fontSize: '24px', lineHeight: '30px', marginTop: '20px'}}>Conducted first materiality assessment</div>
                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              Environmental commitment statement (ECS) with 6 goals for 2020.
                            </p>
                            <br />
                          </>
                          :
                          <>
                          <div className="af-class-timeline-heading" style={{maxWidth: '520px', marginTop: '20px'}}>Conducted first materiality assessment</div>
                          <p ref={(el) => {
                               if (el) {el.style.setProperty('line-height', '30px', 'important');}
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300'}} >
                              Environmental commitment statement (ECS) with 6 goals for 2020.
                          </p>
                          </>
                      }
                    </div>

                    <div className="af-class-year-content-block af-class-_4">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2013</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{fontSize: '24px', lineHeight: '30px', marginTop: '20px'}}>‘Sustainability by Design’ <br/>approach in operations</div>
                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              People - Safety - Quality -
                              Availability - Environment - Engineering Excellence - Productivity -
                              Continuous Improvement - Community
                            </p>
                            <br />
                          </>
                          :
                          <>
                          <div className="af-class-timeline-heading" style={{maxWidth: '520px', marginTop: '20px'}}>‘Sustainability by Design’ <br/>approach in operations</div>
                          <p ref={(el) => {
                               if (el) {el.style.setProperty('line-height', '30px', 'important');}
                            }} style={{ fontSize: '18px', fontWeight: '300'}} >
                              People - Safety - Quality - Availability - Environment - Engineering Excellence - Productivity - Continuous Improvement - Community
                          </p>
                          </>
                      }
                    </div>


                    <div className="af-class-year-content-block af-class-_5">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2016</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{fontSize: '24px', lineHeight: '30px', marginTop: '20px'}}>
                              Delivering good health and going beyond compliance for a sustainable future.
                            </div>
                          </>
                          :

                          <div className="af-class-timeline-heading" style={{maxWidth: '520px', marginTop: '20px'}}>Delivering good health and going beyond compliance for a sustainable future</div>
                      }
                    </div>


                    <div className="af-class-year-content-block af-class-_6">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2020</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{fontSize: '24px', lineHeight: '30px', marginTop: '20px'}}>2010 ECS wrap-up</div>

                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              We achieved three goals completely; three goals partially. Conducted second materiality assessment.
                            </p>
                            <br />
                          </>
                          :
                          <>
                          <div className="af-class-timeline-heading" style={{maxWidth: '520px', marginTop: '20px'}}>2010 ECS wrap-up</div>
                          <p ref={(el) => {
                               if (el) {el.style.setProperty('line-height', '30px', 'important');}
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300'}} >
                              We achieved three goals completely; three goals partially. Conducted second materiality assessment.
                          </p>
                          </>
                      }
                    </div>

                    <div className="af-class-year-content-block af-class-_7">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2022</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{fontSize: '24px', lineHeight: '30px', marginTop: '20px'}}>ESG Goals for 2035</div>

                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              Made sustainability core to our purpose and strategy, strengthened our sustainability commitment for the next decade
                            </p>
                            <br />
                          </>
                          :
                          <>
                          <div className="af-class-timeline-heading" style={{maxWidth: '520px', marginTop: '20px'}}>ESG Goals for 2035</div>
                          <p ref={(el) => {
                               if (el) {el.style.setProperty('line-height', '30px', 'important');}
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300'}} >
                              Made sustainability core to our purpose and strategy, strengthened our sustainability commitment for the next decade
                          </p>
                          </>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </section> */}

            {/* ESG Goals  */}

            {/* <div id="esg-goals" className="af-class-section af-class-z-index-2" style={{}} >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container"> */}

            {/* <ScrollMenu>
                {["Being committed to <br/>environmental stewardship", "Making our products accessible<br/> and affordable to patients",
                "Contributing to a fairer and <br/>more socially inclusive world", "Enhancing trust with our <br/>stakeholders"].map((item, index) => (<div style={{width: "300px"}} dangerouslySetInnerHTML={{__html:item}}></div>)) }
              </ScrollMenu> */}

            {/* <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '30px' }} >
                {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((0-1) * (100 / (2-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (2-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (0 * (100 / 2)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / 2) + "%" }} />
                }
                </div> */}


            {/* </div>
              </div> */}


            {/* <SustainabilityTabs tabData={TabData} state={this.state} updateState={(changeState) => this.setState(changeState)}/>  */}

            <div id="sustainability" className="af-class-section"  >
              <div className="af-class-container w-container" data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7">

                <h2 ref={(el) => {
                  if (el && isTab) {
                    el.style.setProperty('font-size', '36px', 'important')
                    el.style.setProperty('margin-bottom', '20px', 'important')
                  }
                }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin af-class-center"
                  style={{
                    marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '-10px' : '-10px',
                    marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px',
                    fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px', textAlign: 'center'
                  }} >Our current ESG goals</h2>


                {!window.matchMedia('(max-width: 820px)').matches && <MainContainer isTabsEnable={isTabsEnable}>

                  <TabsSection>
                    {isTabsEnable &&
                      this.state.tabData.map((item, index) => {
                        return (
                          <TabItem
                            href={"#" + item.hashValue}
                            key={"Rendering_Tab_contactUs_" + item.id + item.name + index}
                            onClick={() => {
                              this.setState({ selectedTab: item, selectedSubTab: 0 });
                            }}
                            selected={this.state.selectedTab.id === item.id}
                          >
                            {item.name}
                          </TabItem>
                        );
                      })}
                    {!isTabsEnable && (
                      <HambergerButton
                        onClick={() => {
                          this.setState({ openMenuBool: !this.state.openMenuBool });
                        }}
                      >
                        <HambergerIcon />
                      </HambergerButton>
                    )}
                  </TabsSection>
                </MainContainer>}

                {!window.matchMedia('(max-width: 820px)').matches && <HR />}

                {window.matchMedia('(max-width: 820px)').matches &&
                  <MainContainer style={{ flexDirection: 'column', marginTop: 0, marginBottom: '10px' }}>
                    <TabsSection style={{ marginLeft: 0 }}>
                      {isTabsEnable &&
                        <><MobileTabItem
                          href={"#" + this.state.selectedTab.hashValue}
                          key={"Rendering_Tab_contactUs_" + this.state.selectedTab.id + this.state.selectedTab.name}
                          selected={this.state.selectedTab.id}
                        >
                          {this.state.selectedTab.name}
                        </MobileTabItem>
                          <a
                            onClick={() => {
                              var selectedValue = this.state.selectedTab.id + 1;
                              if (selectedValue > 4) selectedValue = 1;

                              this.setState({ selectedTab: this.state.tabData[selectedValue - 1], selectedSubTab: 0 });
                            }}
                          >
                            <div style={{ width: '10px', height: '16px', marginTop: '-10px' }} >
                              <img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" /></div>
                          </a>
                        </>
                      }
                    </TabsSection>
                    <div style={{ marginBottom: '20px', display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '20px' }} >
                      {
                        isTab
                          ?
                          <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((this.state.selectedTab.id - 1 - 1) * (100 / (4 - 1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (4 - 1)) + "%" }} />
                          :
                          <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((this.state.selectedTab.id - 1) * (100 / 4)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / 4) + "%" }} />
                      }
                    </div>

                  </MainContainer>
                }

                {/* {!window.matchMedia('(max-width: 820px)').matches && <div style={{ width: '100%' }} >
                    <ul style={{ display: 'flex', flexWrap: 'wrap', lineHeight: 1.5, paddingLeft: '25px' }} >

                    </ul>
                  </div>} */}

                {/* {!window.matchMedia('(max-width: 820px)').matches && <HR />} */}

                {/* {
                    window.matchMedia('(max-width: 820px)').matches && <div className="disable-scroll-indicator" style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', marginBottom: '50px', width: 'calc(calc(100% + 20px + 20px))', marginLeft: '-20px', marginRight: '-20px', }} >
                      {
                        this.state.tabData.map((item, index) => {
                          return (
                            item.hasOwnProperty("url") && item.url.length !== 0
                              ?
                              <a key={item.id} id={"MainTabs:" + item.id} style={{ textDecoration: this.state.selectedTab?.name === item.name ? 'underline' : 'none', textUnderlineOffset: '4px', color: '#000', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between', marginRight: '15px', marginRight: this.state.tabData.length - 1 === index ? '20px' : '15px', marginLeft: index === 0 && '20px' }}
                                target="_blank" href={item.url}
                              >
                                <p style={{ fontSize: '16px', fontWeight: 'bold', color: this.state.selectedTab?.name === item.name ? '#5225B5' : '#909090', margin: '0px', whiteSpace: 'nowrap' }} >{item.name}</p>
                              </a>
                              :
                              <a key={item.id} id={"MainTabs:" + item.id} style={{ textDecoration: this.state.selectedTab?.name === item.name ? 'underline' : 'none', textUnderlineOffset: '4px', color: '#000', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between', marginRight: '15px', marginRight: this.state.tabData.length - 1 === index ? '20px' : '15px', marginLeft: index === 0 && '20px' }}
                                onClick={() => {
                                  this.setState({ selectedTab: item })
                                }}
                              >
                                <p style={{ fontSize: '16px', fontWeight: 'bold', color: this.state.selectedTab?.name === item.name ? '#5225B5' : '#909090', margin: '0px', whiteSpace: 'nowrap' }} >{item.name}</p>
                              </a>
                          )
                        })
                      }
                    </div>
                  } */}

                {/* { window.matchMedia('(max-width: 820px)').matches && this.state.selectedTab?.subMenuTabData && this.state.selectedTab.subMenuTabData.map((item, index) => {
                      return (
                        item.hasOwnProperty("url") && item.url.length !== 0
                          ?
                          <div key={"MenuListView:" + index} >
                            <a style={{ textDecoration: 'none', color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between' }} target="_blank" href={item.url} >
                              <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px' }} >{item.name}</p>
                              <img  loading="lazy"src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                            </a>
                            <HR />
                          </div>
                          :
                          <div key={"MenuListView:" + index} >
                            <a style={{ textDecoration: 'none', color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between' }} href={"/detail-sub-menu#" + item.hashValue} >
                              <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px' }} >{item.name}</p>
                              <img loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                            </a>
                            <HR />
                          </div>
                      )
                    })
                  } */}


                <div className="af-class-delivering-health-wrapper" style={{ alignItems: 'center', display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >

                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&
                    <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{
                      pointerEvents: this.state.leftDisabled ? 'none' : 'auto',
                      position: 'absolute', top: '30%', left: '1%', marginLeft: '-60px', padding: 0
                    }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={() => this.handleLeftClick()}>

                      {this.state.leftDisabled ?
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                        </>
                        :
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </>
                      }
                    </div>}


                  <div className="af-class-scroll-video-block" style={{
                    width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset',
                    marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                    marginRight: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                    marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
                  }}>

                    <div style={{
                      margin: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '12px 12px 0px 12px'
                    }}>
                      {this.getTabContent(this.state.selectedTab)}
                      <a href="/business-responsibility-and-sustainability" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block"
                        style={{ marginTop: '30px', marginLeft: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '-20px' }}>
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                        <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{
                          fontSize: isTab && '18px', fontWeight: isTab && '600'
                        }} >LEARN&nbsp;MORE</div>
                      </a>
                    </div>
                  </div>
                  {this.getTabImage(this.state.selectedTab)}


                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{
                    pointerEvents: this.state.rightDisabled ? 'none' : 'auto',
                    position: 'absolute', top: '30%', left: '96%'
                  }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={() => this.handleRightClick()}>

                    {this.state.rightDisabled ?
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                      :
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                    }
                  </div>}


                </div>

              </div>
            </div>


            {/* {this.state.openMenuBool && (
                <MenuContainer>
                  <CloseButton
                    onClick={() => {
                      this.setState({openMenuBool: false});
                    }}
                  />
                  <MenuItemContainer>
                    {this.state.tabData.map((item, index) => {
                      return (
                        <TabMenuItem
                          key={"Rendering_TabMenuItem_contactUs_" + item.name + index}
                          onClick={() => {
                            this.setState({ selectedTab: item, openMenuBool: false });
                          }}
                          selected={this.state.selectedTab.name === item.name}
                        >
                          {item.name}
                        </TabMenuItem>
                      );
                    })}
                  </MenuItemContainer>
                </MenuContainer>
              )} */}




            {/* <div id="main" className="af-class-section" style={{paddingLeft:0, paddingRight: 0, paddingBottom: '0', paddingTop: '0', width: '100%',height:'100vh',  backgroundColor: '#ededed'}}  >
          <Carousel showArrows={false} showStatus={false} showThumbs={false} dynamicHeight={false} animationHandler="fade">
                <div style={{width: '60%',height:'100vh', position: 'relative', textAlign: 'center', color: 'white'}}>
                  <img loading="lazy" src="/images/Mask-Group-347.png"  style={{opacity: 0.8}}/>
                  <div style={{position: 'absolute', top: '25%', left: '30%'}}>
                    <p ref={(el)=>{
                      if(el){
                      el.style.setProperty('font-size','80px','important')
                      el.style.setProperty('line-height','100px','important')
                      }
                    }} >We Are <br/> Dr.Reddy's</p>
                  </div>
                    <div style={{position: 'absolute', backgroundColor: 'white', color: 'black', width: '530px', height: '460px', left: '85%', top: '15%',bottom:'25%',alignItems:'center',justifyItems:'center',display:'flex',justifyContent:'center'}}>
                    <div style={{textAlign:'start',fontSize:'40px',lineHeight:'56px'}} >
                        Innovative use of <br/>
                        Integrated Business <br/>
                        Planning and Sales and <br/>
                        Operations Planning in <br/>
                        Uncertain Times <br/>
                        (Pharma)
                    </div>
                    </div>
                </div>
                <div style={{width: '60%',height:'100vh'}}>
                    <img loading="lazy" src="/images/Mask-Group-348.png" />
                </div>
                <div style={{width: '60%',height:'100vh'}}>
                    <img  loading="lazy" src="/images/Mask-Group-349.png" />
                </div>
                <div style={{width: '60%',height:'100vh'}}>
                    <img loading="lazy" src="/images/Mask-Group-350.png" />
                </div>
                <div style={{width: '60%',height:'100vh'}}>
                    <img loading="lazy" src="/images/Mask-Group-351.png" />
                </div>
            </Carousel>
            </div> */}

            <WeAreReddysSlider data={this.state.articleData?.section3 ? this.state.articleData.section3 : []} />
            <WeAreReddys data={this.state.articleData?.section3 ? this.state.articleData.section3 : []} />

            {this.state.newsData && this.state.newsData.length !== 0 && <div id="we-are-in-the-news" className="af-class-section af-class-headlines-section">
              <div data-w-id="acec90c0-4b61-6f0c-884b-1b74a412e816" style={{ opacity: 0 }} className="af-class-container w-container">
                <h1 className="af-class-h2-section-heading" style={{ padding: 0, margin: 0 }} >Latest news</h1>
                <div className="af-class-headlines-wrapper">
                  <div className="af-class-headlines-main-tile"><img src="images/Mask-Group-15.png" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1279px) 53vw, (max-width: 1919px) 56vw, 858px" srcSet="images/Mask-Group-15-p-500.png 500w, images/Mask-Group-15-p-800.png 800w, images/Mask-Group-15.png 814w" alt className="af-class-headlines-main-image" />
                    <div className="af-class-headlines-main-text-overlay" style={{ right: isTab && 0, maxWidth: isTab && '100%' }} >
                      <h3 className="af-class-h2-section-heading" >{minimizeString(this.state.newsData[0].title, window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 45 : 65)}</h3>
                      <a href="/all-news" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                        <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >READ&nbsp;ALL&nbsp;NEWS</div>
                      </a>
                    </div>
                  </div>
                  <div className="af-class-side-headlines-wrapper">
                    {
                      exceptFirstNewsData.map((item, index) => {
                        return <div className="af-class-headline-block">
                          <a href={item.fileBool ? item.fileUrl : `/detail-news?${item.contentId}#${item.id}`} style={{ color: 'black', textDecoration: 'none' }}
                          // onClick={() => {
                          //   localStorage.setItem("newsData", JSON.stringify(item))
                          // }}
                          >
                            <h3 className="af-class-headlines-heading">{item.title}</h3>
                            {/* <div className="af-class-menu-link-underline"/> */}
                          </a>
                          <p className="af-class-headlines-blurb" style={{ lineHeight: '1.8' }} >{minimizeString(item.description, 110)}</p>

                        </div>
                      })
                    }
                    {/* <div className="af-class-headline-block">
                      <h3 className="af-class-headlines-heading">Dr. Reddy’s not as big in India as it should be. We want to grow more</h3>
                      <p className="af-class-headlines-blurb">We plan and act in ways that improve every life we touch - from our employees, to our partners…</p>
                    </div>
                    <div className="af-class-headline-block">
                      <h3 className="af-class-headlines-heading">Why a new expat CEO might be good news for Dr. Reddy’s</h3>
                      <p className="af-class-headlines-blurb">We plan and act in ways that improve every life we touch - from our employees, to our partners…</p>
                    </div>
                    <div className="af-class-headline-block">
                      <h3 className="af-class-headlines-heading">Indian pharma industry: from cultural practices to quality…</h3>
                      <p className="af-class-headlines-blurb">We plan and act in ways that improve every life we touch - from our employees, to our partners…</p>
                    </div>
                    <div className="af-class-headline-block">
                      <h3 className="af-class-headlines-heading">Indian pharma industry: from cultural practices to quality…</h3>
                      <p className="af-class-headlines-blurb">We plan and act in ways that improve every life we touch - from our employees, to our partners…</p>
                    </div>
                    <div className="af-class-headline-block">
                      <h3 className="af-class-headlines-heading">Indian pharma industry: from cultural practices to quality…</h3>
                      <p className="af-class-headlines-blurb">We plan and act in ways that improve every life we touch - from our employees, to our partners…</p>
                    </div>
                    <div className="af-class-headline-block">
                      <h3 className="af-class-headlines-heading">Indian pharma industry: from cultural practices to quality…</h3>
                      <p className="af-class-headlines-blurb">We plan and act in ways that improve every life we touch - from our employees, to our partners…</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>}

            <div className="af-class-section af-class-knowledge-bank" style={{ padding: 0, margin: 0 }} >
              <a href="#main" data-w-id="1e93b0bc-4d73-ee38-8cbc-f2757ef7c754" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            {/* <div className="af-class-section af-class-mediakit"> */}
            {/* <div className="af-class-container w-container"> */}
            {/* <div>OUR&nbsp;RESOURCES</div>
              <div className="af-class-why-spacer" />
              <div className="af-class-media-kit-links-wrapper">
                <div>
                  <div className="af-class-knowledge-bank-text">Our experience and our resources are available to help you achieve more in your field.</div>
                </div>
                <div className="af-class-mediakit-links-inner-block">
                  <a href="#" className="af-class-media-kit-links">Knowledge Centre</a>
                  <a href="#" className="af-class-media-kit-links">Community programmes</a>
                  <a href="#" className="af-class-media-kit-links">Doctor portals</a>
                  <a href="#" className="af-class-media-kit-links af-class-no-right-margin">Learning tools</a>
                </div>
              </div>
              <a href="#about-us-main" data-w-id="fdafa20d-4e8c-ad15-27f2-b8cc017787ea" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a> */}
            {/* </div> */}
          </div>
          {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
            const { cookies } = this.props;
            cookies.set('EnableCookie', JSON.stringify(data));
          }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
          <FooterSection />
          {/* [if lte IE 9]><![endif] */}
        </span>
      </span>
    )
  }
}

export default withCookies(IndexView);

/* eslint-enable */
